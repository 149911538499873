<template>
  <div>

    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" class="field-required"/>
        </el-col>

      </el-row>
      <signature-pad v-if="field.filled_by == 'SENDER'" :field="field"></signature-pad>
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import SignaturePad from "./SignaturePad";
export default {
  name: "templates-formComponents-ESignature",
  components: {
    "TitleAndDescription":()=>import("./TitleAndDescription"),
    "IsFieldRequired":()=>import("./IsFieldRequired"),
    "FieldFilledBy":()=>import("./FieldFilledBy"),
    "SignaturePad":()=>import("./SignaturePad")
  },
  props: ["field","form"],
  data() {
    return {
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1"
        },
        {
          title: "Folder 2",
          _id: "2"
        },
        {
          title: "Folder 3",
          _id: "3"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
</style>